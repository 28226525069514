@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.back[data-v-4ae1e595] {
  margin-top: 0.2rem;
  font-size: 14PX;
  color: #869099;
}
.h5[data-v-4ae1e595] {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.16rem;
  flex-direction: column;
  gap: 0.2rem;
}
.whov[data-v-4ae1e595] {
  width: 100%;
  height: 100vh;
}
